"use client";

import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext } from "react";
const TopAdContext = createContext<UseTopAdContext>({
  isHidden: null,
  setIsHidden: () => {
    /* Nothing yet */
  }
});
export function useTopAd(): UseTopAdContext {
  return useContext(TopAdContext);
}
type UseTopAdContext = {
  readonly isHidden: boolean | null;
  readonly setIsHidden: Dispatch<SetStateAction<boolean | null>>;
};
type TopAdProviderProps = {
  readonly children: ReactNode;
  readonly value: UseTopAdContext;
};
export function TopAdProvider({
  children,
  value
}: TopAdProviderProps) {
  return <TopAdContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="TopAdProvider" data-sentry-source-file="top-ad.context.tsx">{children}</TopAdContext.Provider>;
}